import simplyWhitePng from "../../../resources/images/frameListing/1.simplyWhite.png";
import simplyWhiteWebp from "../../../resources/images/frameListing/1.simplyWhite.webp";
import classicWhitePng from "../../../resources/images/frameListing/2.classicWhite.png";
import classicWhiteWebp from "../../../resources/images/frameListing/2.classicWhite.webp";
import elegantWhitePng from "../../../resources/images/frameListing/3.elegantWhite.png";
import elegantWhiteWebp from "../../../resources/images/frameListing/3.elegantWhite.webp";
import ornamentalWhitePng from "../../../resources/images/frameListing/4.ornamentalWhite.png";
import ornamentalWhiteWebp from "../../../resources/images/frameListing/4.ornamentalWhite.webp";
import simplyBlackPng from "../../../resources/images/frameListing/5.simplyBlack.png";
import simplyBlackWebp from "../../../resources/images/frameListing/5.simplyBlack.webp";
import classicBlackPng from "../../../resources/images/frameListing/6.classicBlack.png";
import classicBlackWebp from "../../../resources/images/frameListing/6.classicBlack.webp";
import plushBlackPng from "../../../resources/images/frameListing/7.plushBlack.png";
import plushBlackWebp from "../../../resources/images/frameListing/7.plushBlack.webp";
import metallicGoldPng from "../../../resources/images/frameListing/9.metallicGold.png";
import metallicGoldWebp from "../../../resources/images/frameListing/9.metallicGold.webp";
import vintageGoldPng from "../../../resources/images/frameListing/10.vintageGold.png";
import vintageGoldWebp from "../../../resources/images/frameListing/10.vintageGold.webp";
import metallicSilverPng from "../../../resources/images/frameListing/13.metallicSilver.png";
import metallicSilverWebp from "../../../resources/images/frameListing/13.metallicSilver.webp";
import vintageSilverPng from "../../../resources/images/frameListing/14.vintageSilver.png";
import vintageSilverWebp from "../../../resources/images/frameListing/14.vintageSilver.webp";
import patternedWalnutPng from "../../../resources/images/frameListing/15.patternedChestnut.png";
import patternedWalnutWebp from "../../../resources/images/frameListing/15.patternedChestnut.webp";
import patternedAspenPng from "../../../resources/images/frameListing/16.patternedAspen.png";
import patternedAspenWebp from "../../../resources/images/frameListing/16.patternedAspen.webp";
import whitePng from "../../../resources/images/frameCategory/white.PNG";
import whiteWebp from "../../../resources/images/frameCategory/white.webp";
import blackPng from "../../../resources/images/frameCategory/black.PNG";
import blackWebp from "../../../resources/images/frameCategory/black.webp";
import aspenPng from "../../../resources/images/frameCategory/aspen.PNG";
import aspenWebp from "../../../resources/images/frameCategory/aspen.webp";
import walnutPng from "../../../resources/images/frameCategory/walnut.PNG";
import walnutWebp from "../../../resources/images/frameCategory/walnut.webp";
import goldPng from "../../../resources/images/frameCategory/gold.PNG";
import goldWebp from "../../../resources/images/frameCategory/gold.webp";
import silverPng from "../../../resources/images/frameCategory/silver.PNG";
import silverWebp from "../../../resources/images/frameCategory/silver.webp";

import blizzard from "../../../resources/images/matboardColours/0BlizzardAQ5001.png";
import paleGrey from "../../../resources/images/matboardColours/17PaleGreyAq5632.png";
import winterWhite from "../../../resources/images/matboardColours/1WinterWhiteAQ5977.PNG";
import minuet from "../../../resources/images/matboardColours/2MinuetAQ5627.PNG";
import hayseed from "../../../resources/images/matboardColours/3HayseedAQ5065.PNG";
import avillonWhite from "../../../resources/images/matboardColours/4AvillonWhiteAQ5661.PNG";
import cream from "../../../resources/images/matboardColours/5CreamAQ5213.PNG";
import dustyCream from "../../../resources/images/matboardColours/6dustyCreamA4028.PNG";
import footballRed from "../../../resources/images/matboardColours/10FootballRedAQ5699.PNG";
import beaujolais from "../../../resources/images/matboardColours/11BeaujolaisAQ5151.PNG";
import oceanBlue from "../../../resources/images/matboardColours/12OceanBlueAQ5686.PNG";
import blueberry from "../../../resources/images/matboardColours/13PeterboroWhiteCoreMatboardBlueberryW568.png";
import seaShell from "../../../resources/images/matboardColours/seashell.png";

import black from "../../../resources/matColours/black.jpg";
import bone from "../../../resources/matColours/bone.jpg";
import chinaWhite from "../../../resources/matColours/chinaWhite.jpg";
import cricket from "../../../resources/matColours/cricketGreen.jpg";
import seaFoam from "../../../resources/matColours/seafoam.jpg";
import ecru from "../../../resources/matColours/ecru.jpg";
import gold from "../../../resources/matColours/gold.png";
import mocha from "../../../resources/matColours/chocolate.jpg";
import navyBlue from "../../../resources/matColours/navyBlue.jpg";
import royalBlue from "../../../resources/matColours/royalBlue.jpg";
import smoothWhite from "../../../resources/matColours/smoothWhite.PNG";
import flagstone from "../../../resources/matColours/flagstone.jpg";
import silver from "../../../resources/matColours/silver.jpg";
import straw from "../../../resources/matColours/straw.jpg";

import wine from "../../../resources/matColours/wine.jpg";
import icePink from "../../../resources/matColours/icePink.jpg";
import persimmon from "../../../resources/matColours/perisimmon.jpg";
import purple from "../../../resources/matColours/purple.jpg";
import cirrus from "../../../resources/matColours/cirrus.jpg";
import flax from "../../../resources/matColours/flax.jpg";
import powderBlue from "../../../resources/matColours/powderBlue.jpg";
import peach from "../../../resources/matColours/peach.jpg";
import lavender from "../../../resources/matColours/lavender.jpg";

import lemon from "../../../resources/matColours/lemon.jpg";
import parchment from "../../../resources/matColours/parchment.jpg";
import blueGrey from "../../../resources/matColours/blueGrey.jpg";
import shamrock from "../../../resources/matColours/shamrock.jpg";
import slate from "../../../resources/matColours/slate.jpg";
import darkGrey from "../../../resources/matColours/darkGrey.jpg";
import azure from "../../../resources/matColours/azure.jpg";



import simplyWhite1 from "../../../resources/images/frameDetailedPhotos/1 simply white/simplyWhiteFront.PNG";
import simplyWhite2 from "../../../resources/images/frameDetailedPhotos/1 simply white/simplyWhiteSide.PNG";

import classicWhite1 from "../../../resources/images/frameDetailedPhotos/2. classic white/classicWhite.PNG";
import classicWhite2 from "../../../resources/images/frameDetailedPhotos/2. classic white/classicWhiteSide.PNG";

import elegantWhite1 from "../../../resources/images/frameDetailedPhotos/3. elegant white/elegantwhite1.PNG";
import elegantWhite2 from "../../../resources/images/frameDetailedPhotos/3. elegant white/elegantwhite2.PNG";

import ornamentalWhite1 from "../../../resources/images/frameDetailedPhotos/4. ornamental white/ornamentalWhite1.PNG";
import ornamentalWhite2 from "../../../resources/images/frameDetailedPhotos/4. ornamental white/ornamentalWhite2.PNG";

import simplyBlack1 from "../../../resources/images/frameDetailedPhotos/5. simply black/simplyBlackFront.PNG";
import simplyBlack2 from "../../../resources/images/frameDetailedPhotos/5. simply black/simplyBlackSide.PNG";

import classicblack1 from "../../../resources/images/frameDetailedPhotos/6. classic black/classicblack1.PNG";
import classicblack2 from "../../../resources/images/frameDetailedPhotos/6. classic black/classicblack2.PNG";

import plushblack1 from "../../../resources/images/frameDetailedPhotos/7 Plush Black/plushblack1.PNG";
import plushblack2 from "../../../resources/images/frameDetailedPhotos/7 Plush Black/plushblack2.PNG";

import metallicgold1 from "../../../resources/images/frameDetailedPhotos/9 Metallic Gold/metallicgold1.PNG";
import metallicgold2 from "../../../resources/images/frameDetailedPhotos/9 Metallic Gold/metallicgold2.PNG";

import vintageGold1 from "../../../resources/images/frameDetailedPhotos/10 Vintage Gold/vintagegold1.PNG";
import vintageGold2 from "../../../resources/images/frameDetailedPhotos/10 Vintage Gold/vintagegold2.PNG";

import metallicSilver1 from "../../../resources/images/frameDetailedPhotos/13 Metallic Silver/metallicsilver1.PNG";
import metallicSilver2 from "../../../resources/images/frameDetailedPhotos/13 Metallic Silver/metallicsilver2.PNG";

import vintageSilver1 from "../../../resources/images/frameDetailedPhotos/14 Vintage Silver/vintagesilver1.PNG";
import vintageSilver2 from "../../../resources/images/frameDetailedPhotos/14 Vintage Silver/vintagesilver2.PNG";

import walnut1 from "../../../resources/images/frameDetailedPhotos/15 Patterned Walnut/walnut1.PNG";
import walnut2 from "../../../resources/images/frameDetailedPhotos/15 Patterned Walnut/walnut2.PNG";
import aspen1 from "../../../resources/images/frameDetailedPhotos/16 Patterned Aspen/aspen1.PNG";
import aspen2 from "../../../resources/images/frameDetailedPhotos/16 Patterned Aspen/aspen2.PNG";

import priceTesting from "./priceTesting";

var shippingPricing1 = 0;
var shippingPricing2 = 0;
var shippingPricing3 = 0;

var assignedPricing;

if (priceTesting() === "1") {
    assignedPricing = shippingPricing1;
} else if (priceTesting() === "2") {
    assignedPricing = shippingPricing2;
} else {
    assignedPricing = shippingPricing3;
}

export const productDB = {
    discountTier: {
        tier1: 0,
        tier2: 0.2,
        tier3: 0.25,
        tier4: 0.3,
        tier5: 0.4,
        tier6: 0.5,
        tier7: 0.6,
        tier8: 0.64,
        tier9: 0.66,
        tier10: 0.68,
        tier11: 0.7,
    },
    frameCategories: {
        white: {
            png: whitePng,
            webp: whiteWebp,
            desc: "White",
            frameInventory: ["simplyWhite", "classicWhite", "elegantWhite", "ornamentalWhite"],
        },
        black: {
            png: blackPng,
            webp: blackWebp,
            desc: "Black",
            frameInventory: ["simplyBlack", "classicBlack", "plushBlack"],

        },
        aspen: {
            png: aspenPng,
            webp: aspenWebp,
            desc: "Aspen",
            frameInventory: ["patternedAspen"],
        },
        walnut: {
            png: walnutPng,
            webp: walnutWebp,
            desc: "Walnut",
            frameInventory: ["patternedWalnut"],
        },
        gold: {
            png: goldPng,
            webp: goldWebp,
            desc: "Gold",
            frameInventory: ["sparklingGold", "metallicGold", "vintageGold", "goldStandard"],
        },
        silver: {
            png: silverPng,
            webp: silverWebp,
            desc: "Silver",
            frameInventory: ["sparklingSilver", "metallicSilver", "vintageSilver"],
        },
    },
    frame: {
        frameLists: {
            classicWhite: {
                png: classicWhitePng,
                webp: classicWhiteWebp,
                productTitle: "Classic White",
                productSizeLowerBound: "7 x 5",
                productSizeUpperBound: "28 x 20",
                productShapeRange: "Rectangular, Square, Panoramic",
                productInfo: [
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                productPriceLowerBound: 29.99,
                productPriceUpperBound: 69.99,
                sizeInventory: {
                    size1: {
                        dimensions: "7 x 5 in (Out of Stock)",
                        width: 7,
                        height: 5,
                        price: 31.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size2: {
                        dimensions: "9 x 9 in (Out of Stock)",
                        width: 9,
                        height: 9,
                        price: 33.98,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 39.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 54.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size5: {
                            dimensions: "20 x 9 in (Out of Stock)",
                        width: 20,
                        height: 9,
                        price: 42.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size6: {
                        dimensions: "20 x 20 in (Square)",
                        width: 20,
                        height: 20,
                        price: 59.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size7: {
                        dimensions: "28 x 20 in",
                        width: 28,
                        height: 20,
                        price: 69.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [classicWhite1, classicWhite2],
            },
            classicBlack: {
                png: classicBlackPng,
                webp: classicBlackWebp,
                productTitle: "Classic Black",
                productSizeLowerBound: "7 x 5",
                productSizeUpperBound: "28 x 20",
                productShapeRange: "Rectangular, Square, Panoramic",
                productInfo: [
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                productPriceLowerBound: 29.99,
                productPriceUpperBound: 69.99,
                sizeInventory: {
                    size1: {
                        dimensions: "7 x 5 in (Out of Stock)",
                        width: 7,
                        height: 5,
                        price: 31.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size2: {
                        dimensions: "9 x 9 in (Out of Stock)",
                        width: 9,
                        height: 9,
                        price: 33.98,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 39.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 54.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size5: {
                        dimensions: "20 x 9 in (Out of Stock)",
                        width: 20,
                        height: 9,
                        price: 42.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size6: {
                        dimensions: "20 x 20 in (Square)",
                        width: 20,
                        height: 20,
                        price: 59.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size7: {
                        dimensions: "28 x 20 in",
                        width: 28,
                        height: 20,
                        price: 69.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [classicblack1, classicblack2],
            },

            patternedWalnut: {
                png: patternedWalnutPng,
                webp: patternedWalnutWebp,
                productTitle: "Patterned Walnut",
                productSizeLowerBound: "7 x 5",
                productSizeUpperBound: "28 x 20",
                productShapeRange: "Rectangular & Square",
                productInfo: [
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                productPriceLowerBound: 24.99,
                productPriceUpperBound: 69.99,
                sizeInventory: {
                    size1: {
                        dimensions: "7 x 5 in (Out of Stock)",
                        width: 7,
                        height: 5,
                        price: 24.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size2: {
                        dimensions: "9 x 9 in (Out of Stock)",
                        width: 9,
                        height: 9,
                        price: 37.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 42.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 54.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size5: {
                        dimensions: "28 x 20 in",
                        width: 27,
                        height: 20,
                        price: 69.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [walnut1, walnut2],
            },
            patternedAspen: {
                png: patternedAspenPng,
                webp: patternedAspenWebp,
                productTitle: "Patterned Aspen",
                productSizeLowerBound: "7 x 5",
                productSizeUpperBound: "20 x 16",
                productShapeRange: "Rectangular & Square",
                productInfo: [
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                productPriceLowerBound: 24.99,
                productPriceUpperBound: 54.99,
                sizeInventory: {
                    size1: {
                        dimensions: "7 x 5 in (Out of Stock)",
                        width: 7,
                        height: 5,
                        price: 24.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size2: {
                        dimensions: "9 x 9 in (Out of Stock)",
                        width: 9,
                        height: 9,
                        price: 37.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 42.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 54.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [aspen1, aspen2],
            },

            metallicGold: {
                png: metallicGoldPng,
                webp: metallicGoldWebp,
                productTitle: "Metallic Gold",
                productSizeLowerBound: "10 x 8",
                productSizeUpperBound: "28 x 20",
                productShapeRange: "Rectangular",
                productInfo: [
                    `Frame - High quality anodized aluminium.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                productPriceLowerBound: 27.99,
                productPriceUpperBound: 69.99,
                sizeInventory: {
                    size1: {
                        dimensions: "10 x 8 in (Out of Stock)",
                        width: 10,
                        height: 8,
                        price: 27.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size2: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 42.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 54.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "28 x 20 in",
                        width: 28,
                        height: 20,
                        price: 69.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [metallicgold1, metallicgold2],
            },
            metallicSilver: {
                png: metallicSilverPng,
                webp: metallicSilverWebp,
                productTitle: "Metallic Silver",
                productSizeLowerBound: "10 x 8",
                productSizeUpperBound: "36 x 24",
                productShapeRange: "Rectangular",
                productInfo: [
                    `Frame - High quality anodized aluminium.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                productPriceLowerBound: 27.99,
                productPriceUpperBound: 69.99,
                sizeInventory: {
                    size1: {
                        dimensions: "10 x 8 in (Out of Stock)",
                        width: 10,
                        height: 8,
                        price: 27.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size2: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 42.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 54.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "28 x 20 in",
                        width: 28,
                        height: 20,
                        price: 69.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [metallicSilver1, metallicSilver2],
            },

            vintageGold: {
                png: vintageGoldPng,
                webp: vintageGoldWebp,
                productTitle: "Vintage Gold",
                productSizeLowerBound: "7 x 5",
                productSizeUpperBound: "28 x 20",
                productShapeRange: "Rectangular",
                productInfo: [
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                productPriceLowerBound: 25.99,
                productPriceUpperBound: 79.99,
                sizeInventory: {
                    size1: {
                        dimensions: "7 x 5 in (Out of Stock)",
                        width: 7,
                        height: 5,
                        price: 25.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size2: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 41.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 54.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "28 x 20 in (Out of Stock)",
                        width: 28,
                        height: 20,
                        price: 79.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [vintageGold1, vintageGold2],
            },
            vintageSilver: {
                png: vintageSilverPng,
                webp: vintageSilverWebp,
                productTitle: "Vintage Silver",
                productSizeLowerBound: "7 x 5",
                productSizeUpperBound: "36 x 24",
                productShapeRange: "Rectangular",
                productInfo: [
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                productPriceLowerBound: 25.99,
                productPriceUpperBound: 79.99,
                sizeInventory: {
                    size1: {
                        dimensions: "7 x 5 in (Out of Stock)",
                        width: 7,
                        height: 5,
                        price: 25.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size2: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 41.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 54.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "28 x 20 in (Out of Stock)",
                        width: 28,
                        height: 20,
                        price: 79.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [vintageSilver1, vintageSilver2],
            },

            simplyWhite: {
                png: simplyWhitePng,
                webp: simplyWhiteWebp,
                productTitle: "Simply White",
                productSizeLowerBound: "16 x 12",
                productSizeUpperBound: "28 x 20",
                productInfo: [
                    `Budget frame for every day use`,
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - Synthetic paper.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                productShapeRange: "Rectangular",
                productPriceLowerBound: 25.99,
                productPriceUpperBound: 37.99,
                sizeInventory: {
                    size3: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 25.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 37.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size5: {
                        dimensions: "28 x 20 in (Out of Stock)",
                        width: 28,
                        height: 20,
                        price: 32.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [simplyWhite1, simplyWhite2],
            },
            simplyBlack: {
                png: simplyBlackPng,
                webp: simplyBlackWebp,
                productTitle: "Simply Black",
                productSizeLowerBound: "16 x 12",
                productSizeUpperBound: "20 x 16",
                productShapeRange: "Rectangular",
                productPriceLowerBound: 25.99,
                productPriceUpperBound: 37.99,
                productInfo: [
                    `Budget frame for every day use`,
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - Synthetic Paper.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                sizeInventory: {
                    // size1: {
                    //     dimensions: "6 x 4 in",
                    //     width: 6,
                    //     height: 4,
                    //     price: 9.99,
                    //     shippingCanada: assignedPricing,
                    //     shippingUSA: assignedPricing,
                    // },
                    // size2: {
                    //     dimensions: "7 x 5 in",
                    //     width: 7,
                    //     height: 5,
                    //     price: 14.99,
                    //     shippingCanada: assignedPricing,
                    //     shippingUSA: assignedPricing,
                    // },
                    size3: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 25.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 37.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [simplyBlack1, simplyBlack2],
            },

            elegantWhite: {
                png: elegantWhitePng,
                webp: elegantWhiteWebp,
                productTitle: "Elegant White",
                productSizeLowerBound: "6 x 4",
                productSizeUpperBound: "16 x 12",
                productShapeRange: "Rectangular",
                productInfo: [
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                productPriceLowerBound: 25.99,
                productPriceUpperBound: 41.99,
                sizeInventory: {
                    // size1: {
                    //     dimensions: "6 x 4 in",
                    //     width: 6,
                    //     height: 4,
                    //     price: 24.99,
                    //     shippingCanada: assignedPricing,
                    //     shippingUSA: assignedPricing,
                    // },
                    size2: {
                        dimensions: "7 x 5 in (Out of Stock)",
                        width: 7,
                        height: 5,
                        price: 25.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "10 x 8 in (Out of Stock)",
                        width: 10,
                        height: 8,
                        price: 27.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "16 x 12 in (Out of Stock)",
                        width: 16,
                        height: 12,
                        price: 41.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [elegantWhite1, elegantWhite2],
            },
            ornamentalWhite: {
                png: ornamentalWhitePng,
                webp: ornamentalWhiteWebp,
                productTitle: "Ornamental White",
                productSizeLowerBound: "7 x 5",
                productSizeUpperBound: "28 x 20",
                productShapeRange: "Rectangular",
                productPriceLowerBound: 24.99,
                productPriceUpperBound: 69.99,
                productInfo: [
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                sizeInventory: {
                    size1: {
                        dimensions: "7 x 5 in (Out of Stock)",
                        width: 7,
                        height: 5,
                        price: 24.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size2: {
                        dimensions: "10 x 8 in (Out of Stock)",
                        width: 10,
                        height: 8,
                        price: 26.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "16 x 12 in (Out of Stock)",
                        width: 16,
                        height: 12,
                        price: 41.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "20 x 16 in (Out of Stock)",
                        width: 20,
                        height: 16,
                        price: 54.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size5: {
                        dimensions: "28 x 20 in (Out of Stock)",
                        width: 28,
                        height: 20,
                        price: 69.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [ornamentalWhite1, ornamentalWhite2],
            },
            plushBlack: {
                png: plushBlackPng,
                webp: plushBlackWebp,
                productTitle: "Plush Black",
                productSizeLowerBound: "7 x 5",
                productSizeUpperBound: "28 x 20",
                productShapeRange: "Rectangular",
                productPriceLowerBound: 24.99,
                productPriceUpperBound: 69.99,
                productInfo: [
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                sizeInventory: {
                    size1: {
                        dimensions: "7 x 5 in (Out of Stock)",
                        width: 7,
                        height: 5,
                        price: 24.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size2: {
                        dimensions: "16 x 12 in (Out of Stock)",
                        width: 16,
                        height: 12,
                        price: 41.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "20 x 16 in (Out of Stock)",
                        width: 20,
                        height: 16,
                        price: 54.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "28 x 20 in (Out of Stock)",
                        width: 28,
                        height: 20,
                        price: 69.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [plushblack1, plushblack2],
            },
        },
    },
    polaroidFrame: {
        frameLists: {
            classicWhite: {
                png: classicWhitePng,
                webp: classicWhiteWebp,
                productTitle: "Classic White",
                productSizeLowerBound: "7 x 5",
                productSizeUpperBound: "28 x 20",
                productShapeRange: "Rectangular, Square, Panoramic",
                productInfo: [
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                productPriceLowerBound: 29.99,
                productPriceUpperBound: 69.99,
                sizeInventory: {
                    size1: {
                        dimensions: "7 x 5 in",
                        width: 7,
                        height: 5,
                        price: 31.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size2: {
                        dimensions: "9 x 9 in (Square)",
                        width: 9,
                        height: 9,
                        price: 33.98,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 39.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 54.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size5: {
                        dimensions: "20 x 9 in (Panoramic)",
                        width: 20,
                        height: 9,
                        price: 42.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size6: {
                        dimensions: "20 x 20 in (Square)",
                        width: 20,
                        height: 20,
                        price: 59.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size7: {
                        dimensions: "28 x 20 in",
                        width: 28,
                        height: 20,
                        price: 69.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [classicWhite1, classicWhite2],
            },
            classicBlack: {
                png: classicBlackPng,
                webp: classicBlackWebp,
                productTitle: "Classic Black",
                productSizeLowerBound: "7 x 5",
                productSizeUpperBound: "28 x 20",
                productShapeRange: "Rectangular, Square, Panoramic",
                productInfo: [
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                productPriceLowerBound: 29.99,
                productPriceUpperBound: 69.99,
                sizeInventory: {
                    size1: {
                        dimensions: "7 x 5 in",
                        width: 7,
                        height: 5,
                        price: 31.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size2: {
                        dimensions: "9 x 9 in (Square)",
                        width: 9,
                        height: 9,
                        price: 33.98,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 39.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 54.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size5: {
                        dimensions: "20 x 9 in (Panoramic)",
                        width: 20,
                        height: 9,
                        price: 42.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size6: {
                        dimensions: "20 x 20 in (Square)",
                        width: 20,
                        height: 20,
                        price: 59.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size7: {
                        dimensions: "28 x 20 in",
                        width: 28,
                        height: 20,
                        price: 69.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [classicblack1, classicblack2],
            },
            patternedWalnut: {
                png: patternedWalnutPng,
                webp: patternedWalnutWebp,
                productTitle: "Patterned Walnut",
                productSizeLowerBound: "7 x 5",
                productSizeUpperBound: "28 x 20",
                productShapeRange: "Rectangular & Square",
                productInfo: [
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                productPriceLowerBound: 24.99,
                productPriceUpperBound: 69.99,
                sizeInventory: {
                    size1: {
                        dimensions: "7 x 5 in",
                        width: 7,
                        height: 5,
                        price: 24.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size2: {
                        dimensions: "9 x 9 in (Square)",
                        width: 9,
                        height: 9,
                        price: 37.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 42.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 54.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size5: {
                        dimensions: "28 x 20 in",
                        width: 27,
                        height: 20,
                        price: 69.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [walnut1, walnut2],
            },
            patternedAspen: {
                png: patternedAspenPng,
                webp: patternedAspenWebp,
                productTitle: "Patterned Aspen",
                productSizeLowerBound: "7 x 5",
                productSizeUpperBound: "20 x 16",
                productShapeRange: "Rectangular & Square",
                productInfo: [
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                productPriceLowerBound: 24.99,
                productPriceUpperBound: 54.99,
                sizeInventory: {
                    size1: {
                        dimensions: "7 x 5 in",
                        width: 7,
                        height: 5,
                        price: 24.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size2: {
                        dimensions: "9 x 9 in (Square)",
                        width: 9,
                        height: 9,
                        price: 37.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 42.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 54.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [aspen1, aspen2],
            },

            simplyWhite: {
                png: simplyWhitePng,
                webp: simplyWhiteWebp,
                productTitle: "Simply White",
                productSizeLowerBound: "16 x 12",
                productSizeUpperBound: "28 x 20",
                productInfo: [
                    `Budget frame for every day use`,
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - Synthetic paper.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                productShapeRange: "Rectangular",
                productPriceLowerBound: 25.99,
                productPriceUpperBound: 37.99,
                sizeInventory: {
                    size3: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 25.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 37.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size5: {
                        dimensions: "28 x 20 in (OUT OF STOCK)",
                        width: 28,
                        height: 20,
                        price: 32.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [simplyWhite1, simplyWhite2],
            },
            simplyBlack: {
                png: simplyBlackPng,
                webp: simplyBlackWebp,
                productTitle: "Simply Black",
                productSizeLowerBound: "16 x 12",
                productSizeUpperBound: "20 x 16",
                productShapeRange: "Rectangular",
                productPriceLowerBound: 25.99,
                productPriceUpperBound: 37.99,
                productInfo: [
                    `Budget frame for every day use`,
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - Synthetic Paper.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                sizeInventory: {
                    // size1: {
                    //     dimensions: "6 x 4 in",
                    //     width: 6,
                    //     height: 4,
                    //     price: 9.99,
                    //     shippingCanada: assignedPricing,
                    //     shippingUSA: assignedPricing,
                    // },
                    // size2: {
                    //     dimensions: "7 x 5 in",
                    //     width: 7,
                    //     height: 5,
                    //     price: 14.99,
                    //     shippingCanada: assignedPricing,
                    //     shippingUSA: assignedPricing,
                    // },
                    size3: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 25.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 37.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [simplyBlack1, simplyBlack2],
            },

            elegantWhite: {
                png: elegantWhitePng,
                webp: elegantWhiteWebp,
                productTitle: "Elegant White",
                productSizeLowerBound: "6 x 4",
                productSizeUpperBound: "16 x 12",
                productShapeRange: "Rectangular",
                productInfo: [
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                productPriceLowerBound: 25.99,
                productPriceUpperBound: 41.99,
                sizeInventory: {
                    // size1: {
                    //     dimensions: "6 x 4 in",
                    //     width: 6,
                    //     height: 4,
                    //     price: 24.99,
                    //     shippingCanada: assignedPricing,
                    //     shippingUSA: assignedPricing,
                    // },
                    size2: {
                        dimensions: "7 x 5 in",
                        width: 7,
                        height: 5,
                        price: 25.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "10 x 8 in",
                        width: 10,
                        height: 8,
                        price: 27.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 41.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [elegantWhite1, elegantWhite2],
            },

            ornamentalWhite: {
                png: ornamentalWhitePng,
                webp: ornamentalWhiteWebp,
                productTitle: "Ornamental White",
                productSizeLowerBound: "7 x 5",
                productSizeUpperBound: "28 x 20",
                productShapeRange: "Rectangular",
                productPriceLowerBound: 24.99,
                productPriceUpperBound: 69.99,
                productInfo: [
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                sizeInventory: {
                    size1: {
                        dimensions: "7 x 5 in",
                        width: 7,
                        height: 5,
                        price: 24.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size2: {
                        dimensions: "10 x 8 in",
                        width: 10,
                        height: 8,
                        price: 26.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 41.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 54.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size5: {
                        dimensions: "28 x 20 in",
                        width: 28,
                        height: 20,
                        price: 69.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [ornamentalWhite1, ornamentalWhite2],
            },

            plushBlack: {
                png: plushBlackPng,
                webp: plushBlackWebp,
                productTitle: "Plush Black",
                productSizeLowerBound: "7 x 5",
                productSizeUpperBound: "28 x 20",
                productShapeRange: "Rectangular",
                productPriceLowerBound: 24.99,
                productPriceUpperBound: 69.99,
                productInfo: [
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                sizeInventory: {
                    size1: {
                        dimensions: "7 x 5 in",
                        width: 7,
                        height: 5,
                        price: 24.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size2: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 41.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 54.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "28 x 20 in",
                        width: 28,
                        height: 20,
                        price: 69.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [plushblack1, plushblack2],
            },

            metallicGold: {
                png: metallicGoldPng,
                webp: metallicGoldWebp,
                productTitle: "Metallic Gold",
                productSizeLowerBound: "10 x 8",
                productSizeUpperBound: "28 x 20",
                productShapeRange: "Rectangular",
                productInfo: [
                    `Frame - High quality anodized aluminium.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                productPriceLowerBound: 27.99,
                productPriceUpperBound: 69.99,
                sizeInventory: {
                    size1: {
                        dimensions: "10 x 8 in",
                        width: 10,
                        height: 8,
                        price: 27.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size2: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 42.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 54.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "28 x 20 in",
                        width: 28,
                        height: 20,
                        price: 69.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [metallicgold1, metallicgold2],
            },
            metallicSilver: {
                png: metallicSilverPng,
                webp: metallicSilverWebp,
                productTitle: "Metallic Silver",
                productSizeLowerBound: "10 x 8",
                productSizeUpperBound: "36 x 24",
                productShapeRange: "Rectangular",
                productInfo: [
                    `Frame - High quality anodized aluminium.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                productPriceLowerBound: 27.99,
                productPriceUpperBound: 69.99,
                sizeInventory: {
                    size1: {
                        dimensions: "10 x 8 in",
                        width: 10,
                        height: 8,
                        price: 27.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size2: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 42.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 54.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "28 x 20 in",
                        width: 28,
                        height: 20,
                        price: 69.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [metallicSilver1, metallicSilver2],
            },

            vintageGold: {
                png: vintageGoldPng,
                webp: vintageGoldWebp,
                productTitle: "Vintage Gold",
                productSizeLowerBound: "7 x 5",
                productSizeUpperBound: "28 x 20",
                productShapeRange: "Rectangular",
                productInfo: [
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                productPriceLowerBound: 25.99,
                productPriceUpperBound: 79.99,
                sizeInventory: {
                    size1: {
                        dimensions: "7 x 5 in",
                        width: 7,
                        height: 5,
                        price: 25.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size2: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 41.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 54.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "28 x 20 in",
                        width: 28,
                        height: 20,
                        price: 79.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [vintageGold1, vintageGold2],
            },
            vintageSilver: {
                png: vintageSilverPng,
                webp: vintageSilverWebp,
                productTitle: "Vintage Silver",
                productSizeLowerBound: "7 x 5",
                productSizeUpperBound: "36 x 24",
                productShapeRange: "Rectangular",
                productInfo: [
                    `Frame - High quality engineered wood.`,
                    `Mat - PH neutralized, archival matboard.`,
                    `Glass - Anti-fragile acrylic plexiglass.`,
                    `Backboard - High quality MDF.`,
                    `Free shipping for orders above CA 49+.`,
                    `Ship within 1 business day.`,
                ],
                productPriceLowerBound: 25.99,
                productPriceUpperBound: 79.99,
                sizeInventory: {
                    size1: {
                        dimensions: "7 x 5 in",
                        width: 7,
                        height: 5,
                        price: 25.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size2: {
                        dimensions: "16 x 12 in",
                        width: 16,
                        height: 12,
                        price: 41.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size3: {
                        dimensions: "20 x 16 in",
                        width: 20,
                        height: 16,
                        price: 54.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                    size4: {
                        dimensions: "28 x 20 in",
                        width: 28,
                        height: 20,
                        price: 79.99,
                        shippingCanada: assignedPricing,
                        shippingUSA: assignedPricing,
                    },
                },
                detailedPhotos: [vintageSilver1, vintageSilver2],
            },


        },
    },
    taxRates: {
        CA: {
            AB: 0.05,
            BC: 0.12,
            MB: 0.12,
            NB: 0.15,
            NL: 0.15,
            NS: 0.15,
            NT: 0.05,
            NU: 0.05,
            ON: 0.13,
            PE: 0.15,
            QC: 0.14975,
            SK: 0.11,
            YT: 0.05,
        },
        US: {
            "AL": 0,
            "AK": 0,
            "AZ": 0,
            "AR": 0,
            "CA": 0,
            "CO": 0,
            "CT": 0,
            "DE": 0,
            "DC": 0,
            "FL": 0,
            "GA": 0,
            "HI": 0,
            "ID": 0,
            "IL": 0,
            "IN": 0,
            "IA": 0,
            "KS": 0,
            "KY": 0,
            "LA": 0,
            "ME": 0,
            "MD": 0,
            "MA": 0,
            "MI": 0,
            "MN": 0,
            "MS": 0,
            "MO": 0,
            "MT": 0,
            "NE": 0,
            "NV": 0,
            "NH": 0,
            "NJ": 0,
            "NM": 0,
            "NY": 0,
            "NC": 0,
            "ND": 0,
            "OH": 0,
            "OK": 0,
            "OR": 0,
            "PA": 0,
            "PR": 0,
            "RI": 0,
            "SC": 0,
            "SD": 0,
            "TN": 0,
            "TX": 0,
            "UT": 0,
            "VT": 0,
            "VA": 0,
            "WA": 0,
            "WV": 0,
            "WI": 0,
            "WY": 0,
        },
        UK: {
            UK: 0.2,
        },
        EU: {
            Austria: 0.20,
            Belgium: 0.21,
            Bulgaria: 0.2,
            Croatia: 0.25,
            Cyprus: 0.19,
            Czechia: 0.21,
            Denmark: 0.25,
            Estonia: 0.22,
            Finland: 0.255,
            France: 0.20,
            Germany: 0.19,
            Greece: 0.24,
            Hungary: 0.27,
            Ireland: 0.23,
            Italy: 0.22,
            Latvia: 0.21,
            Lithuania: 0.21,
            Luxembourg: 0.17,
            Malta: 0.18,
            Netherlands: 0.21,
            Norway: 0.25,
            Poland: 0.23,
            Portugal: 0.23,
            Romania: 0.19,
            Slovakia: 0.23,
            Slovenia: 0.22,
            Spain: 0.21,
            Sweden: 0.25,
            Switzerland: 0.081,
        },
        AU: {
            NSW: 0,
            VIC: 0,
            QLD: 0,
            WA: 0,
            SA: 0,
            TAS: 0,
            ACT: 0,
            NT: 0,
        },
        NZ: {
            NZ: 0,
        }
    },
    matboards: {
        smoothWhite: {
            code: "BW222",
            core: "White Core",
            color: "Smooth White (#1)",
            colorFr: "Smooth White (#1)",
            img: smoothWhite,
            sample: smoothWhite,
            active: true,
            desc: "Smooth, bright white (Bestseller). Slightly cool white with a smooth, even tone.",
            descFr: "Smooth, bright white (Bestseller). Slightly cool white with a smooth, even tone.",
            guideDark: false,
        },
        avillonWhite: {
            code: "aq5661",
            core: "White Core",
            color: "Avillion (#7)",
            img: avillonWhite,
            sample: avillonWhite,
            active: true,
            desc: "Bright, cool white with a subtle texture with flecks in it. Gives a handmade-paper feel.",
            colorFr: "Avillion (#7)",
            descFr: "Bright, cool white with a subtle texture with flecks in it. Gives a handmade-paper feel.",
            guideDark: false,
        },
        chinaWhite: {
            code: "HW6004",
            core: "White Core",
            color: "China White (#2)",
            colorFr: "China White (#2)",
            img: chinaWhite,
            sample: chinaWhite,
            active: true,
            desc: "An off-white with a very slight yellow tint. Closest to mats come with IKEA Frames.",
            descFr: "An off-white with a very slight yellow tint. Closest to mats come with IKEA Frames.",
            guideDark: false,
        },
        bone: {
            code: "HW6003",
            core: "White Core",
            color: "Bone (#3)",
            colorFr: "Bone (#3)",
            img: bone,
            sample: bone,
            active: true,
            desc: "Soft yellow-white. Light, warm, creamy off-white or ivory.",
            descFr: "Soft yellow-white. Light, warm, creamy off-white or ivory.",
            guideDark: false,
        },
        parchment: {
            code: "HW6003",
            core: "White Core",
            img: parchment,
            sample: parchment,
            active: true,
            color: "Parchment (#28)",
            desc: "A soft, mottled cream with a subtle warm undertone. Slightly textured.",
            colorFr: "Parchment (#28)",
            descFr: "A soft, mottled cream with a subtle warm undertone. Slightly textured.",
            guideDark: false,
        },
        ecru: {
            code: "HW6003",
            core: "White Core",
            img: ecru,
            sample: ecru,
            active: true,
            color: "Ecru (#5)",
            desc: "A soft, warm, light cream with a subtle yellow undertone.",
            colorFr: "Ecru (#5)",
            descFr: "A soft, warm, light cream with a subtle yellow undertone.",
            guideDark: false,
        },
        seafoam: {
            code: "HW6303",
            core: "White Core",
            color: "Seafoam (#4)",
            img: seaFoam,
            sample: seaFoam,
            active: true,
            desc: "A medium, warm beige with a subtle gray undertone and a smooth finish.",
            colorFr: "Seafoam (#4)",
            descFr: "A medium, warm beige with a subtle gray undertone and a smooth finish.",
            guideDark: false,
        },
        flax: {
            code: "W532",
            core: "White Core",
            color: "Flax (#23)",
            img: flax,
            sample: flax,
            active: true,
            desc: "A medium, warm brown with a smooth, even tone and a slightly reddish undertone.",
            colorFr: "Flax (#23)",
            descFr: "A medium, warm brown with a smooth, even tone and a slightly reddish undertone.",
            guideDark: false,
        },
        mocha: {
            code: "AQ5007",
            core: "White Core",
            color: "Chocolate (#14)",
            img: mocha,
            sample: mocha,
            active: true,
            desc: "Rich, medium-dark brown: Downham Mocha is known for being a warm, mid-tone brown.",
            colorFr: "Chocolate (#14)",
            descFr: "Rich, medium-dark brown: Downham Mocha is known for being a warm, mid-tone brown.",
            guideDark: true,
        },
        cirrus: {
            code: "W517",
            core: "White Core",
            color: "Cirrus (#22)",
            img: cirrus,
            sample: cirrus,
            active: true,
            desc: "Cool and airy: It evokes a sense of coolness and airiness.",
            colorFr: "Cirrus (#22)",
            descFr: "Cool and airy: It evokes a sense of coolness and airiness.",
            guideDark: false,
        },
        azure: {
            code: "W517",
            core: "White Core",
            color: "Azure (#33)",
            img: azure,
            sample: azure,
            active: true,
            desc: "Bright, clear blue: Azure is known for its vibrant and distinct blue hue.",
            colorFr: "Azure (#33)",
            descFr: "Bright, clear blue: Azure is known for its vibrant and distinct blue hue.",
            guideDark: false,
        },
        powderBlue: {
            code: "W532",
            core: "White Core",
            color: "Powder Blue (#24)",
            img: powderBlue,
            sample: powderBlue,
            active: true,
            desc: "Light, soft blue with a subtle gray undertone. Pale blue with a muted appearance.",
            colorFr: "Powder Blue (#24)",
            descFr: "Light, soft blue with a subtle gray undertone. Pale blue with a muted appearance.",
            guideDark: false,
        },
        royalBlue: {
            code: "HW6203",
            core: "White Core",
            color: "Royal Blue (#8)",
            img: royalBlue,
            sample: royalBlue,
            active: true,
            desc: "Medium-dark, muted blue with a subtle gray undertone.",
            colorFr: "Royal Blue (#8)",
            descFr: "Medium-dark, muted blue with a subtle gray undertone.",
            guideDark: true,
        },
        navyBlue: {
            code: "HW6200",
            core: "White Core",
            color: "Navy Blue (#9)",
            img: navyBlue,
            sample: navyBlue,
            active: true,
            desc: "Dark, muted blue-gray with a slightly cool undertone.",
            colorFr: "Navy Blue (#9)",
            descFr: "Dark, muted blue-gray with a slightly cool undertone.",
            guideDark: true,
        },
        purple: {
            code: "W5054",
            core: "White Core",
            color: "Purple (#21)",
            img: purple,
            sample: purple,
            active: true,
            desc: "Rich, dark purple with a slightly cool undertone.",
            colorFr: "Purple (#21)",
            descFr: "Rich, dark purple with a slightly cool undertone.",
            guideDark: true,
        },
        shamrock: {
            code: "HW6302",
            core: "White Core",
            color: "Shamrock (#30)",
            img: shamrock,
            sample: shamrock,
            active: true,
            desc: "Medium-dark, slightly muted green with a cool undertone.",
            colorFr: "Shamrock (#30)",
            descFr: "Medium-dark, slightly muted green with a cool undertone.",
            guideDark: true,
        },
        cricket: {
            code: "HW6302",
            core: "White Core",
            color: "Cricket Green (#10)",
            img: cricket,
            sample: cricket,
            active: true,
            desc: "Dark, muted green-gray with a cool undertone.",
            colorFr: "Cricket Green (#10)",
            descFr: "Dark, muted green-gray with a cool undertone.",
            guideDark: true,
        },
        lemon: {
            code: "HW6600",
            core: "White Core",
            color: "Lemon (#27)",
            img: lemon,
            sample: lemon,
            active: true,
            desc: "Bright, warm yellow with a slightly muted or soft quality.",
            colorFr: "Lemon (#27)",
            descFr: "Bright, warm yellow with a slightly muted or soft quality.",
            guideDark: false,
        },
        straw: {
            code: "HW6600",
            core: "White Core",
            color: "Straw (#17)",
            img: straw,
            sample: straw,
            active: true,
            desc: "Medium, warm yellow with a distinct orange undertone.",
            colorFr: "Straw (#17)",
            descFr: "Medium, warm yellow with a distinct orange undertone.",
            guideDark: false,
        },
        gold: {
            code: "AQ5246",
            core: "White Core",
            color: "Gold Shiny Foil (#13)",
            img: gold,
            sample: gold,
            active: true,
            desc: "Gold with a metallic effect. Great for double matting diplomas.",
            colorFr: "Gold Shiny Foil (#13)",
            descFr: "Gold with a metallic effect. Great for double matting diplomas.",
            guideDark: true,
        },
        silver: {
            code: "AQ5246",
            core: "White Core",
            color: "Silver Shiny Foil (#18)",
            img: silver,
            sample: silver,
            active: true,
            desc: "Silver with a metallic effect. Great for double matting diplomas.",
            colorFr: "Silver Shiny Foil (#18)",
            descFr: "Silver with a metallic effect. Great for double matting diplomas.",
            guideDark: true,
        },
        blueGray: {
            code: "HW6103",
            core: "White Core",
            color: "Blue Gray (#29)",
            img: blueGrey,
            sample: blueGrey,
            active: true,
            desc: "Medium, muted blue-gray with a cool, neutral undertone.",
            colorFr: "Blue Gray (#29)",
            descFr: "Medium, muted blue-gray with a cool, neutral undertone.",
            guideDark: true,
        },
        flagstone: {
            code: "HW6103",
            core: "White Core",
            color: "Flagstone (#6)",
            img: flagstone,
            sample: flagstone,
            active: true,
            desc: "Medium-dark, muted gray with a subtle green undertone.",
            colorFr: "Flagstone (#6)",
            descFr: "Medium-dark, muted gray with a subtle green undertone.",
            guideDark: true,
        },
        darkGray: {
            code: "HW6103",
            core: "White Core",
            color: "Dark Gray (#32)",
            img: darkGrey,
            sample: darkGrey,
            active: true,
            desc: "Dark, muted brown-gray with a cool, neutral undertone.",
            colorFr: "Dark Gray (#32)",
            descFr: "Dark, muted brown-gray with a cool, neutral undertone.",
            guideDark: true,
        },
        slate: {
            code: "HW6103",
            core: "White Core",
            color: "Slate (#31)",
            img: slate,
            sample: slate,
            active: true,
            desc: "Very dark, muted olive green with a cool, neutral undertone.",
            colorFr: "Slate (#31)",
            descFr: "Very dark, muted olive green with a cool, neutral undertone.",
            guideDark: true,
        },
        smoothBlack: {
            code: "HW6100",
            core: "White Core",
            color: "Black (#15)",
            img: black,
            sample: black,
            active: true,
            desc: "Black with a subtle warm gray undertone.",
            colorFr: "Black (#15)",
            descFr: "Black with a subtle warm gray undertone.",
            guideDark: true,
        },
        icePink: {
            code: "W5007",
            core: "White Core",
            color: "Ice Pink (#19)",
            img: icePink,
            sample: icePink,
            active: true,
            desc: "Light, soft pink with a warm undertone.",
            colorFr: "Ice Pink (#19)",
            descFr: "Light, soft pink with a warm undertone.",
            guideDark: false,
        },
        peach: {
            code: "W632",
            core: "White Core",
            color: "Peach (#25)",
            img: peach,
            sample: peach,
            active: true,
            desc: "Medium-light, warm pink with a distinct peach or coral undertone.",
            colorFr: "Peach (#25)",
            descFr: "Medium-light, warm pink with a distinct peach or coral undertone.",
            guideDark: false,
        },
        lavender: {
            code: "W632",
            core: "White Core",
            color: "Lavender (#26)",
            img: lavender,
            sample: lavender,
            active: true,
            desc: "Medium, muted dusty rose with a cool, slightly gray undertone.",
            colorFr: "Lavender (#26)",
            descFr: "Medium, muted dusty rose with a cool, slightly gray undertone.",
            guideDark: false,
        },
        wine: {
            code: "W5007",
            core: "White Core",
            color: "Wine (#16)",
            img: wine,
            sample: wine,
            active: true,
            desc: "Medium-dark, muted reddish-brown with a cool, slightly gray undertone.",
            colorFr: "Wine (#16)",
            descFr: "Medium-dark, muted reddish-brown with a cool, slightly gray undertone.",
            guideDark: true,
        },
        persimmon: {
            code: "W5045",
            core: "White Core",
            color: "Persimmon (#20)",
            img: persimmon,
            sample: persimmon,
            active: true,
            desc: "Bright, medium-light orange with a warm undertone.",
            colorFr: "Persimmon (#20)",
            descFr: "Bright, medium-light orange with a warm undertone.",
            guideDark: true,
        },
        footballRed: {
            code: "AQ5699",
            core: "White Core",
            color: "Feu (#11)",
            img: footballRed,
            sample: footballRed,
            active: true,
            desc: "Bright red with a warm, slightly orange undertone.",
            colorFr: "Feu (#11)",
            descFr: "Bright red with a warm, slightly orange undertone.",
            guideDark: true,
        },
        beaujolais: {
            code: "AQ5151",
            core: "White Core",
            color: "Rouge (#12)",
            img: beaujolais,
            sample: beaujolais,
            active: true,
            desc: "Medium-dark red with warm orange undertone.",
            colorFr: "Rouge (#12)",
            descFr: "Medium-dark red with warm orange undertone.",
            guideDark: true,
        },
        winterWhite: {
            code: "aq5977",
            core: "White Core",
            color: "Snow (Plain)",
            img: winterWhite,
            sample: winterWhite,
            active: false,
        },

        blizzard: {
            code: "aq5001",
            core: "White Core",
            color: "Blizzard (Plain)",
            img: blizzard,
            sample: blizzard,
            active: false,
        },
        minuet: {
            code: "aq5627",
            core: "White Core",
            color: "Minuet (Plain)",
            img: minuet,
            sample: minuet,
            active: false,
        },
        dustyCream: {
            code: "AQ5701",
            core: "White Core",
            color: "Cancun (Plain)",
            img: dustyCream,
            sample: dustyCream,
            active: false,
        },
        seaShell: {
            code: "aq5698",
            core: "White Core",
            color: "Seashell (Patterned)",
            img: seaShell,
            sample: seaShell,
            active: false,
        },
        hayseed: {
            code: "aq5065",
            core: "White Core",
            color: "Hayseed (Patterned)",
            img: hayseed,
            sample: hayseed,
            active: false,
        },

        cream: {
            code: "aq5213",
            core: "White Core",
            color: "Cream (Patterned)",
            img: cream,
            sample: cream,
            active: false,
        },
        paleGrey: {
            code: "aq5632",
            core: "White Core",
            color: "Pale Gray (Plain)",
            img: paleGrey,
            sample: paleGrey,
            active: false,
        },
        kendallGreen: {
            code: "HW6302",
            core: "White Core",
            color: "Green",
            img: cricket,
            sample: cricket,
            active: false,
        },
        oceanBlue: {
            code: "AQ5686",
            core: "White Core",
            color: "Ocean Blue (Plain)",
            img: oceanBlue,
            sample: oceanBlue,
            active: false,
        },
        blueberry: {
            code: "w568",
            core: "White Core",
            color: "Blueberry (Plain)",
            img: blueberry,
            sample: blueberry,
            active: false,
        },


    },
    recommendedPolaroidLayouts: {
        fujiInstaxMini: {
            "6x4": {
                columnCount: 1,
                rowCount: 1,
                window1: {
                    width: 2,
                    height: 3,
                    marginTop: 0.5,
                    marginBottom: 0.5,
                    marginLeft: 2,
                    marginRight: 2,
                    showModal: false,
                },
            },
            "7x5": {
                columnCount: 1,
                rowCount: 1,
                window1: {
                    width: 2,
                    height: 3,
                    marginTop: 1,
                    marginBottom: 1,
                    marginLeft: 2.5,
                    marginRight: 2.5,
                    showModal: false,
                },
            },
            "9x9": {
                columnCount: 2,
                rowCount: 1,
                window1: {
                    width: 2,
                    height: 3,
                    marginTop: 3,
                    marginBottom: 3,
                    marginLeft: 2,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 2,
                    height: 3,
                    marginTop: 3,
                    marginBottom: 3,
                    marginLeft: 0,
                    marginRight: 2,
                    showModal: false,
                },
            },
            "16x12": {
                rowCount: 2,
                columnCount: 4,
                window1: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 2.5,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window4: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 2.5,
                    showModal: false,
                },
                window5: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 2.5,
                    marginRight: 1,
                    showModal: false,
                },
                window6: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window7: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window8: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 2.5,
                    showModal: false,
                },
            },
            "20x9": {
                columnCount: 5,
                rowCount: 1,
                window1: {
                    width: 2,
                    height: 3,
                    marginTop: 3,
                    marginBottom: 3,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 2,
                    height: 3,
                    marginTop: 3,
                    marginBottom: 3,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 2,
                    height: 3,
                    marginTop: 3,
                    marginBottom: 3,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window4: {
                    width: 2,
                    height: 3,
                    marginTop: 3,
                    marginBottom: 3,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window5: {
                    width: 2,
                    height: 3,
                    marginTop: 3,
                    marginBottom: 3,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },
            },
            "20x16": {
                columnCount: 5,
                rowCount: 3,
                window1: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window4: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window5: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },
                window6: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },
                window7: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window8: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window9: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window10: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },
                window11: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },
                window12: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window13: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window14: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window15: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },


            },
            "20x20": {
                columnCount: 5,
                rowCount: 4,
                window1: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window4: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window5: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },

                window6: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },
                window7: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window8: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window9: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window10: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },

                window11: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },
                window12: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window13: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window14: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window15: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },

                window16: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },
                window17: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window18: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window19: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window20: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },
            },
            "28x20": {
                columnCount: 7,
                rowCount: 3,
                window1: {
                    width: 2,
                    height: 3,
                    marginTop: 4.5,
                    marginBottom: 1,
                    marginLeft: 4,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 2,
                    height: 3,
                    marginTop: 4.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 2,
                    height: 3,
                    marginTop: 4.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window4: {
                    width: 2,
                    height: 3,
                    marginTop: 4.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window5: {
                    width: 2,
                    height: 3,
                    marginTop: 4.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window6: {
                    width: 2,
                    height: 3,
                    marginTop: 4.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window7: {
                    width: 2,
                    height: 3,
                    marginTop: 4.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 4,
                    showModal: false,
                },

                window8: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 4,
                    marginRight: 1,
                    showModal: false,
                },
                window9: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window10: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window11: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window12: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window13: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window14: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 4,
                    showModal: false,
                },

                window15: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 4.5,
                    marginLeft: 4,
                    marginRight: 1,
                    showModal: false,
                },
                window16: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 4.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window17: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 4.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window18: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 4.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window19: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 4.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window20: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 4.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window21: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 4.5,
                    marginLeft: 0,
                    marginRight: 4,
                    showModal: false,
                },
            },
            "4x6": {
                columnCount: 1,
                rowCount: 1,
                window1: {
                    width: 2,
                    height: 3,
                    marginTop: 1.5,
                    marginBottom: 1.5,
                    marginLeft: 1,
                    marginRight: 1,
                    showModal: false,
                },
            },
            "5x7": {
                columnCount: 1,
                rowCount: 1,
                window1: {
                    width: 2,
                    height: 3,
                    marginTop: 2,
                    marginBottom: 2,
                    marginLeft: 1.5,
                    marginRight: 1.5,
                    showModal: false,
                },
            },
            "12x16": {
                columnCount: 3,
                rowCount: 3,
                window1: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 2,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 2.5,
                    showModal: false,
                },

                window4: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 2,
                    marginRight: 1,
                    showModal: false,
                },
                window5: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window6: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 2.5,
                    showModal: false,
                },

                window7: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 2,
                    marginRight: 1,
                    showModal: false,
                },
                window8: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window9: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 2,
                    showModal: false,
                },
            },
            "9x20": {
                columnCount: 2,
                rowCount: 4,
                window1: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 2,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 2,
                    showModal: false,
                },
                window3: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 2,
                    marginRight: 1,
                    showModal: false,
                },
                window4: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 2,
                    showModal: false,
                },
                window5: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 2,
                    marginRight: 1,
                    showModal: false,
                },
                window6: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 2,
                    showModal: false,
                },
                window7: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 2,
                    marginRight: 1,
                    showModal: false,
                },
                window8: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 2,
                    showModal: false,
                },
            },
            "16x20": {
                columnCount: 4,
                rowCount: 4,
                window1: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 2.5,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window4: {
                    width: 2,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 2.5,
                    showModal: false,
                },

                window5: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 2.5,
                    marginRight: 1,
                    showModal: false,
                },
                window6: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window7: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window8: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 2.5,
                    showModal: false,
                },

                window9: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 2.5,
                    marginRight: 1,
                    showModal: false,
                },
                window10: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window11: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window12: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 2.5,
                    showModal: false,
                },

                window13: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 2.5,
                    marginRight: 1,
                    showModal: false,
                },
                window14: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window15: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window16: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 2.5,
                    showModal: false,
                },
            },
            "20x28": {
                columnCount: 4,
                rowCount: 5,

                window1: {
                    width: 2,
                    height: 3,
                    marginTop: 4.5,
                    marginBottom: 1,
                    marginLeft: 4.5,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 2,
                    height: 3,
                    marginTop: 4.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 2,
                    height: 3,
                    marginTop: 4.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window4: {
                    width: 2,
                    height: 3,
                    marginTop: 4.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 4.5,
                    showModal: false,
                },

                window5: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 4.5,
                    marginRight: 1,
                    showModal: false,
                },
                window6: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window7: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window8: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 4.5,
                    showModal: false,
                },

                window9: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 4.5,
                    marginRight: 1,
                    showModal: false,
                },
                window10: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window11: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window12: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 4.5,
                    showModal: false,
                },

                window13: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 4.5,
                    marginRight: 1,
                    showModal: false,
                },
                window14: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window15: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window16: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 4.5,
                    showModal: false,
                },

                window17: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 4.5,
                    marginLeft: 4.5,
                    marginRight: 1,
                    showModal: false,
                },
                window18: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 4.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window19: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 4.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window20: {
                    width: 2,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 4.5,
                    marginLeft: 0,
                    marginRight: 4.5,
                    showModal: false,
                },

            },
        },
        fujiInstaxWide: {
            "6x4": {
                columnCount: 1,
                rowCount: 1,
                window1: {
                    width: 4,
                    height: 3,
                    marginTop: 1,
                    marginBottom: 1,
                    marginLeft: 1,
                    marginRight: 1,
                    showModal: false,
                },
            },
            "7x5": {
                columnCount: 1,
                rowCount: 1,
                window1: {
                    width: 4,
                    height: 3,
                    marginTop: 1,
                    marginBottom: 1,
                    marginLeft: 1.5,
                    marginRight: 1.5,
                    showModal: false,
                },
            },
            "9x9": {
                columnCount: 1,
                rowCount: 1,
                window1: {
                    width: 4,
                    height: 3,
                    marginTop: 3,
                    marginBottom: 3,
                    marginLeft: 2.5,
                    marginRight: 2.5,
                    showModal: false,
                },
            },
            "16x12": {
                rowCount: 2,
                columnCount: 2,
                window1: {
                    width: 4,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 3.5,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 4,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3.5,
                    showModal: false,
                },
                window3: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 3.5,
                    marginRight: 1,
                    showModal: false,
                },
                window4: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 3.5,
                    showModal: false,
                },
            },
            "20x9": {
                columnCount: 3,
                rowCount: 1,
                window1: {
                    width: 4,
                    height: 3,
                    marginTop: 3,
                    marginBottom: 3,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 4,
                    height: 3,
                    marginTop: 3,
                    marginBottom: 3,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 4,
                    height: 3,
                    marginTop: 3,
                    marginBottom: 3,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },
            },
            "20x16": {
                columnCount: 3,
                rowCount: 3,
                window1: {
                    width: 4,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 4,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 4,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },

                window4: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },
                window5: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window6: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },

                window7: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },
                window8: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window9: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },

            },
            "20x20": {
                columnCount: 3,
                rowCount: 4,

                window1: {
                    width: 4,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },

                window2: {
                    width: 4,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },

                window3: {
                    width: 4,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },

                window4: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },

                window5: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },

                window6: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },

                window7: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },

                window8: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },

                window9: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },

                window10: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },

                window11: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },

                window12: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },
            },
            "28x20": {
                columnCount: 4,
                rowCount: 3,
                window1: {
                    width: 4,
                    height: 3,
                    marginTop: 4.5,
                    marginBottom: 1,
                    marginLeft: 4.5,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 4,
                    height: 3,
                    marginTop: 4.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 4,
                    height: 3,
                    marginTop: 4.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window4: {
                    width: 4,
                    height: 3,
                    marginTop: 4.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 4.5,
                    showModal: false,
                },

                window5: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 4.5,
                    marginRight: 1,
                    showModal: false,
                },
                window6: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window7: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window8: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 4.5,
                    showModal: false,
                },

                window9: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 4.5,
                    marginLeft: 4.5,
                    marginRight: 1,
                    showModal: false,
                },
                window10: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 4.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window11: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 4.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window12: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 4.5,
                    marginLeft: 0,
                    marginRight: 4.5,
                    showModal: false,
                },
            },
            "4x6": {
                columnCount: 1,
                rowCount: 1,
                window1: {
                    width: 4,
                    height: 3,
                    marginTop: 1.5,
                    marginBottom: 1.5,
                    marginLeft: 0,
                    marginRight: 0,
                    showModal: false,
                },
            },
            "5x7": {
                columnCount: 1,
                rowCount: 1,
                window1: {
                    width: 4,
                    height: 3,
                    marginTop: 2,
                    marginBottom: 2,
                    marginLeft: 1,
                    marginRight: 1,
                    showModal: false,
                },
            },
            "12x16": {
                columnCount: 1,
                rowCount: 3,
                window1: {
                    width: 4,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 4,
                    marginRight: 4,
                    showModal: false,
                },
                window3: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 4,
                    marginRight: 4,
                    showModal: false,
                },
                window5: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 4,
                    marginRight: 4,
                    showModal: false,
                },
            },
            "9x20": {
                columnCount: 1,
                rowCount: 4,
                window1: {
                    width: 4,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 2.5,
                    marginRight: 2.5,
                    showModal: false,
                },
                window2: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 2.5,
                    marginRight: 2.5,
                    showModal: false,
                },
                window3: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 2.5,
                    marginRight: 2.5,
                    showModal: false,
                },
                window4: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 2.5,
                    marginRight: 2.5,
                    showModal: false,
                },
            },
            "16x20": {
                columnCount: 2,
                rowCount: 4,
                window1: {
                    width: 4,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 3.5,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 4,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3.5,
                    showModal: false,
                },
                window3: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 3.5,
                    marginRight: 1,
                    showModal: false,
                },
                window4: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3.5,
                    showModal: false,
                },
                window5: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 3.5,
                    marginRight: 1,
                    showModal: false,
                },
                window6: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3.5,
                    showModal: false,
                },
                window7: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 3.5,
                    marginRight: 1,
                    showModal: false,
                },
                window8: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 3.5,
                    showModal: false,
                },
            },
            "20x28": {
                columnCount: 3,
                rowCount: 6,

                window1: {
                    width: 4,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 4,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 4,
                    height: 3,
                    marginTop: 2.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },

                window4: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },
                window5: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window6: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },

                window7: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },
                window8: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window9: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },

                window10: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },
                window11: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window12: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },

                window13: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },
                window14: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window15: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },

                window16: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 3,
                    marginRight: 1,
                    showModal: false,
                },
                window17: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window18: {
                    width: 4,
                    height: 3,
                    marginTop: 0,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 3,
                    showModal: false,
                },


            },
        },
        polaroidRegular: {
            "6x4": {
                columnCount: 1,
                rowCount: 1,
                window1: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0.125,
                    marginBottom: 0.125,
                    marginLeft: 1.375,
                    marginRight: 1.375,
                    showModal: false,
                },
            },
            "7x5": {
                columnCount: 1,
                rowCount: 1,
                window1: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0.625,
                    marginBottom: 0.625,
                    marginLeft: 1.875,
                    marginRight: 1.875,
                    showModal: false,
                },
            },
            "9x9": {
                columnCount: 1,
                rowCount: 1,
                window1: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 2.625,
                    marginBottom: 2.625,
                    marginLeft: 2.875,
                    marginRight: 2.875,
                    showModal: false,
                },
            },
            "16x12": {
                rowCount: 2,
                columnCount: 3,
                window1: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 1.75,
                    marginBottom: 1,
                    marginLeft: 2.125,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 1.75,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 1.75,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 2.125,
                    showModal: false,
                },
                window4: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1.75,
                    marginLeft: 2.125,
                    marginRight: 1,
                    showModal: false,
                },
                window5: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1.75,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window6: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1.75,
                    marginLeft: 0,
                    marginRight: 2.125,
                    showModal: false,
                },
            },
            "20x9": {
                columnCount: 4,
                rowCount: 1,
                window1: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 2.625,
                    marginBottom: 2.625,
                    marginLeft: 2,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 2.625,
                    marginBottom: 2.625,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 2.625,
                    marginBottom: 2.625,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window4: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 2.625,
                    marginBottom: 2.625,
                    marginLeft: 0,
                    marginRight: 2,
                    showModal: false,
                },
            },
            "20x16": {
                columnCount: 3,
                rowCount: 2,

                window1: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 3.75,
                    marginBottom: 1,
                    marginLeft: 4.125,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 3.75,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 3.75,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 4.125,
                    showModal: false,
                },
                window4: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 3.75,
                    marginLeft: 4.125,
                    marginRight: 1,
                    showModal: false,
                },
                window5: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 3.75,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window6: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 3.75,
                    marginLeft: 0,
                    marginRight: 4.125,
                    showModal: false,
                },
            },
            "20x20": {
                columnCount: 3,
                rowCount: 3,
                window1: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 3.375,
                    marginBottom: 1,
                    marginLeft: 4.125,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 3.375,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 3.375,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 4.125,
                    showModal: false,
                },

                window4: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 4.125,
                    marginRight: 1,
                    showModal: false,
                },
                window5: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window6: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 4.125,
                    showModal: false,
                },

                window7: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 3.375,
                    marginLeft: 4.125,
                    marginRight: 1,
                    showModal: false,
                },
                window8: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 3.375,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window9: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 3.375,
                    marginLeft: 0,
                    marginRight: 4.125,
                    showModal: false,
                },
            },
            "28x20": {
                columnCount: 5,
                rowCount: 3,

                window1: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 3.375,
                    marginBottom: 1,
                    marginLeft: 3.875,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 3.375,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 3.375,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window4: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 3.375,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window5: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 3.375,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3.875,
                    showModal: false,
                },

                window6: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 3.875,
                    marginRight: 1,
                    showModal: false,
                },
                window7: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window8: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window9: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window10: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3.875,
                    showModal: false,
                },

                window11: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 3.375,
                    marginLeft: 3.875,
                    marginRight: 1,
                    showModal: false,
                },
                window12: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 3.375,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window13: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 3.375,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window14: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 3.375,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window15: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 3.375,
                    marginLeft: 0,
                    marginRight: 3.875,
                    showModal: false,
                },
            },
            "4x6": {
                columnCount: 1,
                rowCount: 1,
                window1: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 1.125,
                    marginBottom: 1.125,
                    marginLeft: 0.375,
                    marginRight: 0.375,
                    showModal: false,
                },
            },
            "5x7": {
                columnCount: 1,
                rowCount: 1,
                window1: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 1.625,
                    marginBottom: 1.625,
                    marginLeft: 0.875,
                    marginRight: 0.875,
                    showModal: false,
                },
            },
            "12x16": {
                columnCount: 1,
                rowCount: 2,
                window1: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 3.75,
                    marginBottom: 1,
                    marginLeft: 2.875,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 2.875,
                    showModal: false,
                },
                window3: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 3.75,
                    marginLeft: 2.875,
                    marginRight: 1,
                    showModal: false,
                },
                window4: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 3.75,
                    marginLeft: 0,
                    marginRight: 2.875,
                    showModal: false,
                },
            },
            "9x20": {
                columnCount: 1,
                rowCount: 3,
                window1: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 3.375,
                    marginBottom: 1,
                    marginLeft: 2.875,
                    marginRight: 2.875,
                    showModal: false,
                },
                window2: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 2.875,
                    marginRight: 2.875,
                    showModal: false,
                },
                window3: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 3.375,
                    marginLeft: 2.875,
                    marginRight: 2.875,
                    showModal: false,
                },
            },
            "16x20": {
                columnCount: 3,
                rowCount: 3,
                window1: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 3.375,
                    marginBottom: 1,
                    marginLeft: 2.125,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 3.375,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 3.375,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 2.125,
                    showModal: false,
                },
                window4: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 2.125,
                    marginRight: 0,
                    showModal: false,
                },
                window5: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window6: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 2.125,
                    showModal: false,
                },
                window7: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 3.375,
                    marginLeft: 2.125,
                    marginRight: 1,
                    showModal: false,
                },
                window8: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 3.375,
                    marginLeft: 2.125,
                    marginRight: 1,
                    showModal: false,
                },
                window9: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 3.375,
                    marginLeft: 0,
                    marginRight: 2.125,
                    showModal: false,
                },
            },
            "20x28": {
                columnCount: 4,
                rowCount: 5,

                window1: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 2.625,
                    marginBottom: 1,
                    marginLeft: 2,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 2.625,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 2.625,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window4: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 2.625,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 2,
                    showModal: false,
                },

                window5: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 2,
                    marginRight: 1,
                    showModal: false,
                },
                window6: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window7: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window8: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 2,
                    showModal: false,
                },

                window9: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 2,
                    marginRight: 1,
                    showModal: false,
                },
                window10: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window11: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window12: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 2,
                    showModal: false,
                },

                window13: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 2,
                    marginRight: 1,
                    showModal: false,
                },
                window14: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window15: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window16: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 2,
                    showModal: false,
                },


                window17: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 3.675,
                    marginLeft: 2,
                    marginRight: 1,
                    showModal: false,
                },

                window18: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 3.675,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },

                window19: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 3.675,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },


                window20: {
                    width: 3.25,
                    height: 3.75,
                    marginTop: 0,
                    marginBottom: 3.675,
                    marginLeft: 0,
                    marginRight: 2,
                    showModal: false,
                },








            },
        },
        polaroidWide: {
            "6x4": {
                columnCount: 1,
                rowCount: 1,
                window1: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 0,
                    marginLeft: 1.125,
                    marginRight: 1.125,
                    showModal: false,
                },
            },
            "7x5": {
                columnCount: 1,
                rowCount: 1,
                window1: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0.5,
                    marginBottom: 0.5,
                    marginLeft: 1.125,
                    marginRight: 1.125,
                    showModal: false,
                },
            },
            "9x9": {
                columnCount: 1,
                rowCount: 1,
                window1: {
                    width: 3.75,
                    height: 4,
                    marginTop: 2.5,
                    marginBottom: 2.5,
                    marginLeft: 2.625,
                    marginRight: 2.625,
                    showModal: false,
                },
            },
            "16x12": {
                rowCount: 2,
                columnCount:2 ,
                window1: {
                    width: 3.75,
                    height: 4,
                    marginTop: 4,
                    marginBottom: 1,
                    marginLeft: 3.75,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 3.75,
                    height: 4,
                    marginTop: 4,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3.75,
                    showModal: false,
                },
                window3: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 4,
                    marginLeft: 3.75,
                    marginRight: 1,
                    showModal: false,
                },
                window4: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 4,
                    marginLeft: 0,
                    marginRight: 3.75,
                    showModal: false,
                },
            },
            "20x9": {
                columnCount: 3,
                rowCount: 1,
                window1: {
                    width: 3.75,
                    height: 4,
                    marginTop: 2.5,
                    marginBottom: 2.5,
                    marginLeft: 3.375,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 3.75,
                    height: 4,
                    marginTop: 2.5,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 3.75,
                    height: 4,
                    marginTop: 2.5,
                    marginBottom: 2.5,
                    marginLeft: 0,
                    marginRight: 3.375,
                    showModal: false,
                },
            },
            "20x16": {
                columnCount: 3,
                rowCount: 2,

                window1: {
                    width: 3.75,
                    height: 4,
                    marginTop: 3.5,
                    marginBottom: 1,
                    marginLeft: 3.375,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 3.75,
                    height: 4,
                    marginTop: 3.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 3.75,
                    height: 4,
                    marginTop: 3.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3.375,
                    showModal: false,
                },
                window4: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 3.5,
                    marginLeft: 3.375,
                    marginRight: 1,
                    showModal: false,
                },
                window5: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 3.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window6: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 3.5,
                    marginLeft: 0,
                    marginRight: 3.375,
                    showModal: false,
                },
            },
            "20x20": {
                columnCount: 3,
                rowCount: 3,
                window1: {
                    width: 3.75,
                    height: 4,
                    marginTop: 3,
                    marginBottom: 1,
                    marginLeft: 3.375,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 3.75,
                    height: 4,
                    marginTop: 3,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 3.75,
                    height: 4,
                    marginTop: 3,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3.375,
                    showModal: false,
                },

                window4: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 3.375,
                    marginRight: 1,
                    showModal: false,
                },
                window5: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window6: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3.375,
                    showModal: false,
                },

                window7: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 3,
                    marginLeft: 3.375,
                    marginRight: 1,
                    showModal: false,
                },
                window8: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 3,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window9: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 3,
                    marginLeft: 0,
                    marginRight: 3.375,
                    showModal: false,
                },
            },
            "28x20": {
                columnCount: 5,
                rowCount: 3,

                window1: {
                    width: 3.75,
                    height: 4,
                    marginTop: 3,
                    marginBottom: 1,
                    marginLeft: 2.625,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 3.75,
                    height: 4,
                    marginTop: 3,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 3.75,
                    height: 4,
                    marginTop: 3,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window4: {
                    width: 3.75,
                    height: 4,
                    marginTop: 3,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window5: {
                    width: 3.75,
                    height: 4,
                    marginTop: 3,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 2.625,
                    showModal: false,
                },

                window6: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 2.625,
                    marginRight: 1,
                    showModal: false,
                },
                window7: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window8: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window9: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window10: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 2.625,
                    showModal: false,
                },

                window11: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 3,
                    marginLeft: 2.625,
                    marginRight: 1,
                    showModal: false,
                },
                window12: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 3,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window13: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 3,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window14: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 3,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window15: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 3,
                    marginLeft: 0,
                    marginRight: 2.625,
                    showModal: false,
                },
            },
            "4x6": {
                columnCount: 1,
                rowCount: 1,
                window1: {
                    width: 3.75,
                    height: 4,
                    marginTop: 1,
                    marginBottom: 1,
                    marginLeft: 0.125,
                    marginRight: 0.125,
                    showModal: false,
                },
            },
            "5x7": {
                columnCount: 1,
                rowCount: 1,
                window1: {
                    width: 3.75,
                    height: 4,
                    marginTop: 1.5,
                    marginBottom: 1.5,
                    marginLeft: 0.625,
                    marginRight: 0.625,
                    showModal: false,
                },
            },
            "12x16": {
                columnCount: 1,
                rowCount: 2,
                window1: {
                    width: 3.75,
                    height: 4,
                    marginTop: 3.5,
                    marginBottom: 1,
                    marginLeft: 4.125,
                    marginRight: 4.125,
                    showModal: false,
                },
                window2: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 3.5,
                    marginLeft: 4.125,
                    marginRight: 4.125,
                    showModal: false,
                },
            },
            "9x20": {
                columnCount: 1,
                rowCount: 3,
                window1: {
                    width: 3.75,
                    height: 4,
                    marginTop: 3,
                    marginBottom: 1,
                    marginLeft: 2.625,
                    marginRight: 2.625,
                    showModal: false,
                },
                window2: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 2.625,
                    marginRight: 2.625,
                    showModal: false,
                },
                window3: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 3,
                    marginLeft: 2.625,
                    marginRight: 2.625,
                    showModal: false,
                },
            },
            "16x20": {
                columnCount: 2,
                rowCount: 3,
                window1: {
                    width: 3.75,
                    height: 4,
                    marginTop: 3,
                    marginBottom: 1,
                    marginLeft: 3.75,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 3.75,
                    height: 4,
                    marginTop: 3,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3.75,
                    showModal: false,
                },

                window3: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 3.75,
                    marginRight: 1,
                    showModal: false,
                },
                window4: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3.75,
                    showModal: false,
                },

                window5: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 3,
                    marginLeft: 3.75,
                    marginRight: 1,
                    showModal: false,
                },
                window6: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 3,
                    marginLeft: 0,
                    marginRight: 3.75,
                    showModal: false,
                },


            },
            "20x28": {
                columnCount: 3,
                rowCount: 4,

                window1: {
                    width: 3.75,
                    height: 4,
                    marginTop: 4.5,
                    marginBottom: 1,
                    marginLeft: 3.375,
                    marginRight: 1,
                    showModal: false,
                },
                window2: {
                    width: 3.75,
                    height: 4,
                    marginTop: 4.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window3: {
                    width: 3.75,
                    height: 4,
                    marginTop: 4.5,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3.375,
                    showModal: false,
                },

                window4: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 3.375,
                    marginRight: 1,
                    showModal: false,
                },
                window5: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window6: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3.375,
                    showModal: false,
                },

                window7: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 3.375,
                    marginRight: 1,
                    showModal: false,
                },
                window8: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window9: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 0,
                    marginRight: 3.375,
                    showModal: false,
                },

                window10: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 4.5,
                    marginLeft: 3.375,
                    marginRight: 1,
                    showModal: false,
                },
                window11: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 4.5,
                    marginLeft: 0,
                    marginRight: 1,
                    showModal: false,
                },
                window12: {
                    width: 3.75,
                    height: 4,
                    marginTop: 0,
                    marginBottom: 4.5,
                    marginLeft: 0,
                    marginRight: 3.375,
                    showModal: false,
                },




            },
        },

    },
    matboardOnlyPricing: {
        //new pricing

        xSmall: {
            //small meaning that fits into letter package for canada post
            width: 10.1,
            height: 10.1,
            price: 14.95,
            //[Canada, US],
            secondaryMat: 4.95,
            shippingCosts: [assignedPricing, assignedPricing, assignedPricing],
        },
        small1: {
            //small meaning that fits into letter package for canada post
            width: 11.1,
            height: 14.1,
            price: 17.95,
            //[Canada, US],
            secondaryMat: 5.95,
            shippingCosts: [assignedPricing, assignedPricing, assignedPricing],
        },
        small2: {
            //small meaning that fits into letter package for canada post
            width: 12.1,
            height: 16.1,
            price: 19.95,
            //[Canada, US],
            secondaryMat: 5.95,
            shippingCosts: [assignedPricing, assignedPricing, assignedPricing],
        },
        medium1: {
            //small meaning that fits into letter package for canada post
            width: 14.1,
            height: 18.1,
            price: 24.95,
            //[Canada, US],
            secondaryMat: 6.95,
            shippingCosts: [assignedPricing, assignedPricing, assignedPricing],
        },
        medium2: {
            //small meaning that fits into letter package for canada post
            width: 16.1,
            height: 16.1,
            price: 26.95,
            //[Canada, US],
            secondaryMat: 7.95,
            shippingCosts: [assignedPricing, assignedPricing, assignedPricing],
        },
        medium3: {
            //small meaning that fits into letter package for canada post
            width: 16.1,
            height: 20.1,
            price: 28.95,
            //[Canada, US],
            secondaryMat: 7.95,
            shippingCosts: [assignedPricing, assignedPricing, assignedPricing],
        },
        large1: {
            //small meaning that fits into letter package for canada post
            width: 18.1,
            height: 24.1,
            price: 34.95,
            //[Canada, US],
            secondaryMat: 9.95,
            shippingCosts: [assignedPricing, assignedPricing, assignedPricing],
        },
        large2: {
            //small meaning that fits into letter package for canada post
            width: 20.1,
            height: 28.1,
            price: 38.95,
            //[Canada, US],
            secondaryMat: 12.95,
            shippingCosts: [assignedPricing, assignedPricing, assignedPricing],
        },
        xlarge1: {
            //small meaning that fits into letter package for canada post
            width: 24.1,
            height: 30.1,
            price: 44.95,
            //[Canada, US],
            secondaryMat: 12.95,
            shippingCosts: [assignedPricing, assignedPricing, assignedPricing],
        },
        xlarge2: {
            //small meaning that fits into letter package for canada post
            width: 24.1,
            height: 36.1,
            price: 47.95,
            //[Canada, US],
            secondaryMat: 15.95,
            shippingCosts: [assignedPricing, assignedPricing, assignedPricing],
        },
        xlarge3: {
            //small meaning that fits into letter package for canada post
            width: 32.1,
            height: 40.1,
            price: 59.95,
            //[Canada, US],
            secondaryMat: 17.95,
            shippingCosts: [assignedPricing, assignedPricing, assignedPricing],
        },

        //code refactoring needed for the elements below.

        //
        // small: {
        //     //small meaning that fits into legal package for canada post
        //     width: 14.1,
        //     height: 11.1,
        //     price: 11.45,
        //     //[Canada, US],
        //     secondaryMat: 5.95,
        //     shippingCosts: [assignedPricing, assignedPricing, assignedPricing],
        // },
        // mediumSmall: {
        //     width: 16.1,
        //     height: 12.1,
        //     price: 14.95,
        //     secondaryMat: 8.95,
        //     shippingCosts: [assignedPricing, assignedPricing, assignedPricing],
        // },
        // medium: {
        //     width: 20.1,
        //     height: 20.1,
        //     price: 19.95,
        //     secondaryMat: 12.95,
        //     shippingCosts: [assignedPricing, assignedPricing, assignedPricing],
        // },
        // large: {
        //     width: 27.6,
        //     height: 27.6,
        //     price: 31.95,
        //     secondaryMat: 14.95,
        //     shippingCosts: [assignedPricing, assignedPricing, assignedPricing],
        // },
        // xLarge: {
        //     width: 40.1,
        //     height: 32.1,
        //     price: 39.95,
        //     secondaryMat: 19.95,
        //     shippingCosts: [assignedPricing, assignedPricing, assignedPricing],
        // },
        //
        // // NEW PRICING
        //


    },
    matboardByFrameSize: {
        '3x3':{width:3, height:3},
        '3x5':{width:3, height:5},
        '3.5x5':{width:3.5, height:5},
        '4x5':{width:4, height:5},
        '4x6':{width:4, height:6},
        '4x7':{width:4, height:7},
        '4x10':{width:4, height:10},
        '5x3':{width:5, height:3},
        '5x3.5':{width:5, height:3.5},
        '5x4':{width:5, height:4},
        '5x5':{width:5, height:5},
        '5x7':{width:5, height:7},
        '6x4':{width:6, height:4},
        '6x8':{width:6, height:8},
        '6x12':{width:6, height:12},
        '7x4':{width:7, height:4},
        '7x5':{width:7, height:5},
        '7x9':{width:7, height:9},
        '7x11':{width:7, height:11},
        '8x6':{width:8, height:6},
        '8x8':{width:8, height:8},
        '8x10':{width:8, height:10},
        '8x12':{width:8, height:12},
        '8x21':{width:8, height:21},
        '8x32':{width:8, height:32},
        '8.5x11':{width:8.5, height:11},
        '9x7':{width:9, height:7},
        '9x11':{width:9, height:11},
        '9x12':{width:9, height:12},
        '10x4':{width:10, height:4},
        '10x8':{width:10, height:8},
        '10x12':{width:10, height:12},
        '10x13':{width:10, height:13},
        '10x14':{width:10, height:14},
        '10x20':{width:10, height:20},
        '11x7':{width:11, height:7},
        '11x8.5':{width:11, height:8.5},
        '11x9':{width:11, height:9},
        '11x11':{width:11, height:11},
        '11x13':{width:11, height:13},
        '11x14':{width:11, height:14},
        '11x15':{width:11, height:15},
        '11x17':{width:11, height:17},
        '11x22':{width:11, height:22},
        '11.75x36':{width:11.75, height:36},
        '12x8':{width:12, height:8},
        '12x9':{width:12, height:9},
        '12x10':{width:12, height:10},
        '12x12':{width:12, height:12},
        '12x15':{width:12, height:15},
        '12x16':{width:12, height:16},
        '12x18':{width:12, height:18},
        '12x36':{width:12, height:36},
        '12x6':{width:12, height:6},
        '13x10':{width:13, height:10},
        '13x11':{width:13, height:11},
        '13x17':{width:13, height:17},
        '13x19':{width:13, height:19},
        '13x15.5':{width:13, height:15.5},
        '13.5x40':{width:13.5, height:40},
        '13.5x24':{width:13.5, height:24},
        '14x10':{width:14, height:10},
        '14x11':{width:14, height:11},
        '14x17':{width:14, height:17},
        '14x18':{width:14, height:18},
        '14x20':{width:14, height:20},
        '15x11':{width:15, height:11},
        '15x12':{width:15, height:12},
        '15.5x13':{width:15.5, height:13},
        '16x12':{width:16, height:12},
        '16x16':{width:16, height:16},
        '16x20':{width:16, height:20},
        '16x24':{width:16, height:24},
        '16.5x25':{width:16.5, height:25},
        '17x11':{width:17, height:11},
        '17x13':{width:17, height:13},
        '17x14':{width:17, height:14},
        '18x12':{width:18, height:12},
        '18x14':{width:18, height:14},
        '18x18':{width:18, height:18},
        '18x24':{width:18, height:24},
        '19x13':{width:19, height:13},
        '19.75x19.75':{width:19.75, height:19.75},
        '19.75x27.5':{width:19.75, height:27.5},
        '20x10':{width:20, height:10},
        '20x14':{width:20, height:14},
        '20x16':{width:20, height:16},
        '20x20':{width:20, height:20},
        '20x24':{width:20, height:24},
        '20x26':{width:20, height:26},
        '20x28':{width:20, height:28},
        '20x30':{width:20, height:30},
        '21x8':{width:21, height:8},
        '22x11':{width:22, height:11},
        '22x28':{width:22, height:28},
        '22x30':{width:22, height:30},
        '24x16':{width:24, height:16},
        '24x18':{width:24, height:18},
        '24x20':{width:24, height:20},
        '24x30':{width:24, height:30},
        '24x35.75':{width:24, height:35.75},
        '24x36':{width:24, height:36},
        '24x13.5':{width:24, height:13.5},
        '25x16.5':{width:25, height:16.5},
        '25x31':{width:25, height:31},
        '26x20':{width:26, height:20},
        '27x40':{width:27, height:40},
        '27.5x19.75':{width:27.5, height:19.75},
        '28x20':{width:28, height:20},
        '28x22':{width:28, height:22},
        '30x20':{width:30, height:20},
        '30x22':{width:30, height:22},
        '30x24':{width:30, height:24},
        '30x40':{width:30, height:40},
        '31x25':{width:31, height:25},
        '32x8':{width:32, height:8},
        '35.75x24':{width:35.75, height:24},
        '36x12':{width:36, height:12},
        '36x24':{width:36, height:24},
        '36x11.75':{width:36, height:11.75},
        '40x27':{width:40, height:27},
        '40x30':{width:40, height:30},
        '40x13.5':{width:40, height:13.5},
    }
};